import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Button from "../components/common/Button"
import RichText from "../components/richText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faAt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    prismic {
      allContact_pages {
        edges {
          node {
            page_description
            page_title
            form_fields {
              field_name
              field_type
              field_placeholder
              required
            }
            content
            title
            address
            address_label
            email_address
            email_label
            phone_label
            phone_number
          }
        }
      }
    }
  }
`
const Form = styled.form`
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  color: ${p => p.theme.color.black};

  > div {
    margin-top: 20px;
  }

  input,
  textarea {
    height: 50px;
    width: 100%;
    padding: 15px;
    font-size: ${p => p.theme.fontSize.normal};
    border: 1px solid ${p => p.theme.color.gray.light};
    border-radius: 8px;
    &:focus:valid {
      border-left-color: ${p => p.theme.color.green.normal};
      border-left-width: 8px;
    }
    &:focus:invalid {
      border-left-color: ${p => p.theme.color.red.normal};
      border-left-width: 8px;
    }
  }

  textarea {
    min-height: 300px;
    resize: none;
  }

  div.gdpr-consent {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0 0 0 10px;
      font-size: ${p => p.theme.fontSize.small};

      a {
        font-size: inherit;
        font-weight: bold;
      }
    }

    input[type="checkbox"] {
      margin: 10px;
      width: 15px;
      border: 2px solid ${p => p.theme.color.green.lighter};
      border-radius: 8px;
    }
  }
`

const Content = styled.section`
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
`

const ContactDetailsWrapper = styled.section`
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
`

const ContactDetails = styled.address`
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;

  background-color: ${p => p.theme.color.white};
  border-radius: 8px;

  box-shadow: 0px 7px 15px rgba(130, 130, 130, 0.5);

  .icon {
    color: ${p => p.theme.color.green.normal};
    width: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    font-style: normal;

    > div {
      min-width: 100px;
      > p {
        margin-bottom: 0;
      }
    }

    > div.icon-wrapper {
      min-width: 0;
      padding-right: 10px;
    }
  }
`

const SubmitButton = styled.button``

const ContactUs = props => {
  const prismicConetent = props.data.prismic.allContact_pages.edges[0]
  if (!prismicConetent) return null

  const document = prismicConetent.node

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{document.page_title}</title>
        <meta name="description" content={document.page_description} />
      </Helmet>

      <Layout>
        <Content>
          <RichText render={document.title} />
          <RichText render={document.content} />
        </Content>
        <ContactDetailsWrapper>
          <ContactDetails>
            <div className="row">
              <div className="icon-wrapper">
                <FontAwesomeIcon className="icon" icon={faPhone} size="lg" />
              </div>
              <div>{document.phone_label}</div>
              <div>{document.phone_number}</div>
            </div>
            <div className="row">
              <div className="icon-wrapper">
                <FontAwesomeIcon className="icon" icon={faAt} size="lg" />
              </div>
              <div>
                <a href={`mailto:${document.email_address}`}>
                  {document.email_label}
                </a>
              </div>
              <div>
                <a href={`mailto:${document.email_address}`}>
                  {document.email_address}
                </a>
              </div>
            </div>
            <div className="row">
              <div className="icon-wrapper">
                <FontAwesomeIcon
                  className="icon"
                  icon={faMapMarkerAlt}
                  size="lg"
                />
              </div>
              <div>{document.address_label}</div>
              <div>
                <RichText render={document.address} />
              </div>
            </div>
          </ContactDetails>
        </ContactDetailsWrapper>

        <Form
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="Kontaktformular"
          action="/danke"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Kontaktformular" />

          {document.form_fields.map((field, i) => {
            if (field.field_type === "textarea") {
              return (
                <div key={i}>
                  <label htmlFor={field.field_name}>
                    {field.field_name}
                    <textarea
                      name={field.field_name}
                      placeholder={field.field_placeholder}
                      required={field.required === "Yes"}
                    />
                  </label>
                </div>
              )
            } else {
              return (
                <div key={i}>
                  <label htmlFor={field.field_name}>
                    {field.field_name}
                    <input
                      id={field.field_name}
                      name={field.field_name}
                      type={field.field_type}
                      placeholder={field.field_placeholder}
                      required={field.required === "Yes"}
                    />
                  </label>
                </div>
              )
            }
          })}
          <div className="gdpr-consent">
            <input
              type="checkbox"
              id="Datenschutz_zustimmung"
              name="Datenschutz_zustimmung"
              required
            />
            <p>
              Ich habe die <Link to="/datenschutz">Datenschutzhinweise</Link>{" "}
              zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten
              zur Beantwortung meiner Anfrage elektronisch erhoben und
              gespeichert werden. Hinweis: Sie können Ihre Einwilligung
              jederzeit widerrufen.
            </p>
          </div>

          <Button>
            <SubmitButton type="submit">Nachricht absenden</SubmitButton>
          </Button>
        </Form>
      </Layout>
    </>
  )
}

export default ContactUs
